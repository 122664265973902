export const DataSet1 = [
  {
    id: 1,
    Visitors: 30,
    Years: "Jan",
  },

  {
    id: 2,
    Visitors: 40,
    Years: "Feb",
  },

  {
    id: 3,
    Visitors: 50,
    Years: "Mar",
  },

  {
    id: 4,
    Visitors: 60,
    Years: "Apr",
  },

  {
    id: 5,
    Visitors: 20,
    Years: "May",
  },

  {
    id: 6,
    Visitors: 76,
    Years: "Jun",
  },

  {
    id: 7,
    Visitors: 92,
    Years: "Jul",
  },

  {
    id: 8,
    Visitors: 92,
    Years: "Aug",
  },

  {
    id: 9,
    Visitors: 92,
    Years: "Sep",
  },

  {
    id: 10,
    Visitors: 92,
    Years: "Oct",
  },

  {
    id: 11,
    Visitors: 92,
    Years: "Nov",
  },

  {
    id: 12,
    Visitors: 92,
    Years: "Dec",
  },
];
